import axios from "axios";

const axiosClientInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_URL
      : "http://localhost:4001",
});

const axiosClient = {
  instance: axiosClientInstance,
};

export default axiosClient;
